import React from "react";
import contactIcon1 from "../assets/images/contact/contact-icon-1.png";
import contactIcon2 from "../assets/images/contact/contact-icon-2.png";
import contactIcon3 from "../assets/images/contact/contact-icon-3.png";
import contactBackImg from "../assets/images/contact/contact-img-1.png";
import contactFrontImg from "../assets/images/contact/contact-img-2.png";
import terminos from "../assets/media/Terminos_y_condiciones_APP_Urbanizaciones.pdf"
function Section6() {
  return (
    <section className="section_6 py-5 position-relative overflow-hidden">
      <div className="row align-items-center justify-content-center m-0">
        <div className="col-10 col-sm-8 col-lg-5 col-xl-4 contact-info justify-content-lg-center">
          <div className="row m-0 justify-content-center">
            <div className="col-12 col-md-10">
              <div className="text-primary my-3">
              <h6 className="mb-2">Contacto</h6>
              <div className="d-flex align-items-center mb-3">
                <img src={contactIcon1} className="icon-img me-3" alt="icon-img" />
                <p className="">3730100</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={contactIcon2} className="icon-img me-3" alt="icon-img" />
                <p className="">contactotribu@bancoguayaquil.com</p>
              </div>
              <div className="d-flex align-items-center mb-3">
                <img src={contactIcon3} className="icon-img me-3" alt="icon-img" />
                <p className="">
                  Panamá y Víctor Manuel Rendón Guayaquil - Ecuador
                </p>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div className="col-10 col-sm-8 col-lg-5 col-xl-4 my-5 my-lg-0 text-center contact-link">
          <p>Conoce nuestros:</p>
          <a href={terminos} className="">
            Términos y condiciones <br />
            Políticas de privacidad
          </a>
        </div>
        <div className="col-10 col-sm-8 col-lg-2 col-xl-4">
          <img src={contactBackImg} className="contact-back" alt="contact-back-img" />
          <img src={contactFrontImg} className="contact-front" alt="contact-front-img" />
        </div>
      </div>
    </section>
  );
}

export default Section6;
