import * as React from "react";
import { Helmet } from "react-helmet"
import Layout from "../components/Layout";
import Header from "../components/Header";
import Section1 from "../components/Section1";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import Section5 from "../components/Section5";
import Section6 from "../components/Section6";
import Footer from "../components/Footer";

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tribu-Te acerca a tu comunidad</title>
        <link rel="canonical" href="https://tribu.com.ec" />
        <meta name="facebook-domain-verification" content="mda5s47gyzwaj6gwzj4kkqea4azeci" />
      </Helmet>
      <div className="container-fluid p-0">
        <Header />
        <Section1 />
        <Section2 />
        <Section3 />
        <Section4 />
        <Section5 />
        <Section6 />
        <Footer />
      </div>
    </Layout>
  )
}

export default IndexPage
