import React from "react";
import triangle1 from "../assets/images/icons/triangle-1.png";
import triangle2 from "../assets/images/icons/triangle-2.png";
import triangle3 from "../assets/images/icons/triangle-3.png";
import Link from "react-scroll/modules/components/Link";

function Section4() {
  return (
    <section id="costos" className="section_4 px-3 py-5 p-md-5">
      <div className="row m-0 justify-content-around p-0 p-xl-4 text-primary">
        <div className="col-12 col-lg-8 col-xl-7 px-0 px-md-3">
          <div className="price-card px-2 px-md-3 py-5 position-relative display-flex">
            <div className="card-content mx-2 mx-xl-4 w-100">
              <div className="card-top display-flex flex-column">
                <div className="d-flex justify-content-center">
                  <h1 className="fs-extraBold dollar-sign">$</h1>
                  <h1 className="fs-extraBold card-no m-0">50</h1>
                </div>
                <h3 className="fs-extraBold">AL MES</h3>
              </div>
              <div className="card-bottom bg-primary display-flex text-center">
                <h5 className="w-100 mx-2">Costo por suscripción</h5>
              </div>
            </div>
            <div className="card-content mx-2 mx-xl-4 w-100">
              <div className="card-top display-flex flex-column">
                <h3 className="fs-extraBold">PAGO ÚNICO DE</h3>
                <div className="d-flex justify-content-center">
                  <h1 className="fs-extraBold dollar-sign">$</h1>
                  <h1 className="fs-extraBold card-no m-0">70</h1>
                </div>
              </div>
              <div className="card-bottom bg-primary display-flex text-center">
                <h5 className="w-100 mx-2">Para botón de pagos con tarjetas de crédito</h5>
              </div>
            </div>
            <div className="price-header">
              <h5 className="fs-bold">COSTOS</h5>
            </div>
          </div>
        </div>
        <div className="col-8 col-md-7 col-lg-4 col-xl-4 mt-5 mt-lg-0">
          <div className="price-card px-4 py-5 position-relative d-flex align-items-center">
            <div className="card-content mx-0 mx-md-3 mx-xl-4 w-100">
              <div className="card-top display-flex flex-column bg-primary">
                <div className="d-flex justify-content-center">
                  <h1 className="fs-extraBold card-no m-0">3</h1>
                </div>
                <h3 className="text-center fs-extraBold">MESES GRATIS*</h3>
              </div>
              <div className="card-bottom display-flex text-center">
                <h6 className="w-100 mx-2">
                  Al contratarla en: <br />
                  Abril: 3 meses gratis <br />
                  Mayo: 2 meses gratis <br />
                  Junio: 1 mes gratis
                </h6>
              </div>
            </div>
            <img src={triangle1} className="triangle-1 position-absolute" alt="triangle-img" />
            <img src={triangle2} className="triangle-2 position-absolute" alt="triangle-img" />
            <img src={triangle3} className="triangle-3 position-absolute" alt="triangle-img" />
            <div className="price-header">
              <h5 className="fs-bold">PROMOCIÓN</h5>
            </div>
          </div>
        </div>
        <div className="col-12 text-center mt-5">
        <Link to="contáctanos" spy={true}>
          <button className="btn">CONTRATAR AHORA</button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Section4;
