import React, { useState } from "react";
import MenImg from "../assets/images/men-img.png";
import WomenImg from "../assets/images/women-img.png";
import icon1 from "../assets/images/icons/icon-1.png";
import icon2 from "../assets/images/icons/icon-2.png";
import icon3 from "../assets/images/icons/icon-3.png";
import icon4 from "../assets/images/icons/icon-4.png";
import icon5 from "../assets/images/icons/icon-5.png";
import icon6 from "../assets/images/icons/icon-6.png";
import icon7 from "../assets/images/icons/icon-7.png";
import icon8 from "../assets/images/icons/icon-8.png";
import icon9 from "../assets/images/icons/icon-9.png";
import icon10 from "../assets/images/icons/icon-10.png";
import icon11 from "../assets/images/icons/icon-11.png";
import icon12 from "../assets/images/icons/icon-12.png";
import icon13 from "../assets/images/icons/icon-13.png";

function Section3() {
  const [administradores, setAdministradores] = useState(3);
  const [residentes, setResidentes] = useState(3);

  const [showResidentes, setShowResidentes] = useState(true);
  const [showAdministradores, setShowAdministradores] = useState(true);
  const beneficiosAdministradores = [
    { icon: icon1, text: "Gestionar y dar seguimiento en tiempo real a los pagos de tus residentes." },
    { icon: icon2, text: "Generar y enviar cuentas de cobro a tus residentes." },
    { icon: icon3, text: "Recibir pagos inmediatos desde cualquier cuenta bancaria o tarjeta, esto te permitirá reducir los tiempos de mora de tus residentes." },
    { icon: icon4, text: "Revisar los ingresos y egresos generales." },
    { icon: icon5, text: "Aprobar las solicitudes de reservas de áreas comunes de tus residentes desde la plataforma." },
    { icon: icon6, text: "Enviar cartas personalizadas a tus residentes para cobros y citas." },
    { icon: icon7, text: "Publicar novedades sobre tu urbanización de forma directa. ¡Todos tus residentes podrán verlas!" },
  ];

  const beneficiosResidentes = [
    { icon: icon3, text: "Pagar tus alícuotas y gastos comunes de forma inmediata, con cualquier cuenta de banco o tarjeta." },
    { icon: icon1, text: "Consultar cobros y pagos del último año." },
    { icon: icon8, text: "Reservar áreas comunes de forma fácil, sin necesidad de llamar o acercarse a una oficina." },
    { icon: icon9, text: "Realizar consultas al administrador." },
    { icon: icon10, text: "Conocer novedades sobre tu urbanización directamente desde la plataforma." },
    { icon: icon2, text: "Llevar un mejor control de tus pagos a la urbanización." },
    { icon: icon11, text: "Configurar números de emergencia." },
    { icon: icon12, text: "Tus pagos con tarjetas de crédito Banco Guayaquil acumulan el DOBLE de millas." },
    { icon: icon13, text: "Realiza tus pagos SIN COSTO, por transferencia bancaria desde tu cuenta Banco Guayaquil." },
  ];

  const setViewBenefits = (type) => {
    if (type === "administradores") {
      setShowAdministradores(false);
      const administradoresNo = administradores === 3 ? beneficiosAdministradores.length : 3;
      setAdministradores(administradoresNo);
    } else {
      setShowResidentes(false);
      const residentesNo = residentes === 3 ? beneficiosResidentes.length : 3;
      setResidentes(residentesNo);
    }
  };

  return (
    <section id="beneficios" className="section_3">
      <div className="row m-0 justify-content-center pt-4 overflow-hidden">
        <div className="col-10 col-md-10 col-xl-9 my-4">
          <h3 className="col-6 col-md-9 fs-extraBold text-dark-gray benefit-header"> BENEFICIOS ADMINISTRADORES </h3>
        </div>
        <div className="row m-0 justify-content-around benefits-1">
          <div className="col-6 col-xl-6 my-4 px-0 px-sm-2 z-index-1">
            {beneficiosAdministradores.slice(0, administradores).map((e, i) => {
              return (
                <div className="d-flex align-items-start mb-3" key={i}>
                  <div className="col-md-1 col-2 text-center">
                    <img src={e.icon} className="icon-img" alt="icon-img" />
                  </div>
                  <div className="col-10 col-lg-8 ms-2 ms-md-0">
                    <p className="text-light-gray benefit-text">{e.text}</p>
                  </div>
                </div>
              );
            })}
            <div className="d-flex align-items-center">
              <div className="col-md-1 col-2"></div>
              <div className="col-10 col-lg-8">
                { showAdministradores && <button
                  className="btn"
                  onClick={() => setViewBenefits("administradores")}
                >
                  CONOCE MÁS
                </button>}
              </div>
            </div>
          </div>
          <div className="col-5 col-md-4 col-lg-3 col-xl-2 text-end position-relative">
            <img src={MenImg} className="men-img" alt="men-img" />
          </div>
        </div>
      </div>

      <div className="row m-0 justify-content-center pt-4 overflow-hidden">
        <div className="col-10 col-md-10 col-xl-9 d-flex justify-content-end my-4">
          <h3 className="col-6 col-md-9 fs-extraBold text-dark-gray text-end benefit-header"> BENEFICIOS RESIDENTES </h3>
        </div>
        <div className="row m-0 justify-content-around benefits-2">
          <div className="col-5 col-md-4 col-lg-3 col-xl-2 text-end position-relative">
            <img src={WomenImg} className="women-img" alt="women-img" />
          </div>
          <div className="col-6 col-xl-6 my-4 px-0 px-sm-2 z-index-1">
            {beneficiosResidentes.slice(0, residentes).map((e, i) => {
              return (
                <div className="d-flex align-items-start mb-3" key={i}>
                  <div className="col-md-1 col-2 text-center">
                    <img src={e.icon} className="icon-img" alt={e.icon} />
                  </div>
                  <div className="col-10 col-lg-8 ms-2 ml-ms-0">
                    <p className="text-light-gray benefit-text">{e.text}</p>
                  </div>
                </div>
              );
            })}
            <div className="d-flex align-items-center">
              <div className="col-md-1 col-2"></div>
              <div className="col-10 col-lg-8">
                {showResidentes && <button
                  className="btn"
                  onClick={() => setViewBenefits("residentes")}
                >
                  CONOCE MÁS
                </button>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Section3;
