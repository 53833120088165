import React from "react";
import Link from "react-scroll/modules/components/Link";

function Section5() {
  return (
    <section className="section_5 py-5 display-flex flex-column position-relative">
      <h2 className="fs-bold text-light-gray py-4">¿TE GUSTÓ LO QUE VISTE?</h2>
      <h4 className="col-12 col-md-9 col-lg-8 text-dark-gray p-4 text-center">
        Ahorra tiempo y recursos administrando tu condominio y urbanización.
        Conéctate con tus residentes y siéntete más cerca de tu comunidad.
      </h4>
      <Link to="contáctanos" spy={true} ><button className="btn my-3">CONTÁCTANOS</button></Link>
    </section>
  );
}

export default Section5;
